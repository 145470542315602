import React from "react";
import {
  Routes,
  Route,
  useLocation,
  useParams,
  useMatch,
} from "react-router-dom";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Gm_Dev_Africa360view_Africa360View_AggregateArgs,
  Africa360ViewBanjulPropertiesFragment,
  useGetAfrica360ViewBanjulPropertiesFilterQuery,
} from "graphql/hasura/types-and-hooks";
// Utils
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";

// Components
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";
import { formatNumber } from "../../../utils/math";
import ValuationTab from "../valuation-tab/valuation-tab.component";
import UtilitiesTab from "../utilities-tab/utilities-tab.component";
import DetailsTab from "../details-tab/details-tab.component";
import OwnerTab from "../owner-tab/owner-tab.component";
import PropertyNotes from "../notes-tab/notes-tab.component";

// Styles
import SC from "./properties-tab.styles";

export interface IndividualTabRouteParams {
  repId: string;
}

interface Props {
  rMatchdata: Africa360ViewBanjulPropertiesFragment;
}

interface AggregateProperty {
  label: string;
  accessorKey: any;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PropertiesTabPage = ({ rMatchdata }: Props) => {
  const match = useMatch("*");
  const path = match?.pathname ?? "";
  const pathArr = path.split("/");
  pathArr.pop();

  const appPermissionValidator = useAppPermissionValidator();

  const Uuid = rMatchdata.Uuid || ""; // TODO: camvId is not used
  // const PropertyNo = rMatchdata.PropertyNo || ""; // TODO: camvId is not used
  // const customerId = rMatchdata.CustomerId || ""; // TODO: camvId is not used
  // const NwscReference = rMatchdata.NwscCustomerId; // TODO: camvId is not used

  const propertyAddressGroup: AggregateProperty[] = [
    {
      label: "UUID",
      accessorKey: "Rnid" as keyof typeof rMatchdata,
    },
    { label: "Building Type", accessorKey: "Buildingtype" },
    { label: "Business Name Observed in 2024", accessorKey: "Busname" },
    { label: "Phone 1 Observed in 2024", accessorKey: "Phone1" },
    { label: "Phone 2 Observed in 2024", accessorKey: "Phone2" },
  ];
  const locationGroup: AggregateProperty[] = [
    { label: "Street", accessorKey: "Streetname" },
    { label: "Settlement", accessorKey: "Settlement" },
    { label: "Plus Code 1", accessorKey: "Pluscode1" },
    { label: "Plus Code 2", accessorKey: "Pluscode2" },
  ];

  const queryVariables: Query_RootRtcs_Gm_Dev_Africa360view_Africa360View_AggregateArgs =
    {
      where: { Uuid: { _eq: Uuid } },
      limit: 1,
    };

  const { data, error } = useGetAfrica360ViewBanjulPropertiesFilterQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !Uuid,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const handleError = useAppErrorHandler(error);

  // In order to avoid the warning raised in the browser inspector's console:
  // "Cannot update a component (ErrorBoundary) while rendering a different component (IndividualTabPage)",
  // we need to call handleError inside of useEffect.
  React.useEffect(() => {
    if (
      data &&
      data.rtcs_gm_dev_Fnafrica360view_Africa360ViewBanjul.length === 0
    ) {
      handleError(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          `Invalid Search Params`,
        ),
      );
    }
  }, [data, handleError]);

  // memoize the data
  const propertyData = React.useMemo(
    () => data?.rtcs_gm_dev_Fnafrica360view_Africa360ViewBanjul[0] || null,
    [data],
  );

  if (!propertyData) return null;

  const propertiesHeader = [
    {
      index: 0,
      // @ts-ignore
      label: propertyData.Address ?? "",
      type: "bold",
    },
    { index: 1, label: "RNID", type: "bold" },
    { index: 2, label: Uuid },
  ];

  const headerContent = [
    {
      index: 0,
      title: "Building Information",
      data: [
        ...propertyAddressGroup.map(({ accessorKey, label }, index) => {
          // eslint-disable-next-line no-return-assign
          return {
            label,
            index,
            value:
              accessorKey === "Rnid"
                ? Uuid
                : accessorKey === "PropertyNo"
                  ? Uuid
                  : // @ts-ignore
                    propertyData[accessorKey] || "",
          };
        }),
      ],
    },

    {
      index: 1,
      title: "Location",
      data: [
        ...locationGroup.map(({ accessorKey, label }, index) => {
          return {
            label,
            index,
            // @ts-ignore
            value: propertyData[accessorKey],
          };
        }),
      ],
    },

    /*{
      index: 2,
      title: "Valuation ",
      data: [
        ...valuationGroup.map(({ accessorKey, label, format }, index) => {
          return {
            label,
            index,
            value:
              accessorKey === "GrossValue"
                ? formatNumber(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    propertyData[accessorKey],
                  )
                : // @ts-ignore
                  propertyData[accessorKey] || "",
            format,
          };
        }),
      ],
    }, */
  ];
  const tabsData = [
    { label: "Details", path: "details", value: 0 },
    /*{ label: "Owner", path: "owner", value: 1 },
    { label: "Utilities", path: "utilities", value: 2 },
    { label: "Valuation", path: "valuation", value: 3 }, */
    /*{ label: "Field Data", path: "field-data", value: 4 },*/
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // let CamvId: any = null;
  // if (data.rtcs_db_Fnaggregate_Property.length > 0) {
  //   CamvId = data.rtcs_db_Fnaggregate_Property[0].CamvId ?? "";
  // }

  interface RouteConfig {
    path: string | string[];
    element: JSX.Element;
  }

  const routes: RouteConfig[] = [
    /*{
      path: "utilities",
      element: <UtilitiesTab reference={NwscReference} />,
    },
    {
      path: "valuation",
      element: <ValuationTab Rnid={Rnid} />,
    }, */
    {
      path: "details",
      element: <DetailsTab propertyData={propertyData} Rnid={Uuid} />,
    },
    /*{
      path: "owner",
      element: (
        <OwnerTab camvId={propertyData.CamvId || ""} propertyNo={PropertyNo} />
      ),
    },*/
  ];

  if (appPermissionValidator?.(APP_PERMISSION.PROPERTIES_VIEW_NOTES)) {
    tabsData.push({ label: "Notes", path: "notes", value: 5 });
    routes.push({
      path: `notes/:noteID?`,
      element: <PropertyNotes />,
    });
  }

  routes.push({
    path: "*",
    element: <>Content Not Found</>,
  });
  // @ts-ignore
  const { BlockNo, PlotNo, Street } = propertyData;

  return (
    <SC.Container disableGutters>
      <SC.Breadcrumb />
      <SC.ListSubheader>
        <SC.SubHeader
          headerData={{
            header: propertiesHeader,
            content: headerContent,
            source: "Africa 360 View",
          }}
          tabsData={tabsData}
        />
      </SC.ListSubheader>
      <SC.Box>
        <Routes>
          {routes.map((route, index) =>
            Array.isArray(route.path) ? (
              route.path.map((p) => (
                <Route key={`${index}-${p}`} path={p} element={route.element} />
              ))
            ) : (
              <Route key={index} path={route.path} element={route.element} />
            ),
          )}
        </Routes>
      </SC.Box>
    </SC.Container>
  );
};

export default PropertiesTabPage;
