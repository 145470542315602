// Dependencies
import React from "react";

// Components
import { WaterAndElectricityCustomersTable } from "../water-and-electricity-customers-table/water-and-electricity-customers-table.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// Assets
import SC from "./water-and-electricity-customers.styles";

const WaterAndElectricityCustomers: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <WaterAndElectricityCustomersTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default WaterAndElectricityCustomers; 