import React from "react";
import {
  Routes,
  Route,
  useMatch,
} from "react-router-dom";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Africa360ViewKanifingFragment,
  useGetAfrica360ViewKanifingPropertiesFilterQuery,
  Query_RootRtcs_Gm_Dev_Africa360view_Africa360ViewKanifing_AggregateArgs,
} from "graphql/hasura/types-and-hooks";
// Utils
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";

// Components
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";
import DetailsTab from "../kanifing-properties-details-tab/details-tab.component";
import PropertyNotes from "../notes-tab/notes-tab.component";

// Styles
import SC from "./properties-tab.styles";

interface Props {
  rMatchdata: Africa360ViewKanifingFragment;
}

interface AggregateProperty {
  label: string;
  accessorKey: keyof Africa360ViewKanifingFragment | 'Rnid' | 'PropertyNo'; // Allow special case keys
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

const PropertiesTabPage = ({ rMatchdata }: Props) => {
  const match = useMatch("*");
  const path = match?.pathname ?? "";
  const pathArr = path.split("/");
  pathArr.pop();

  const appPermissionValidator = useAppPermissionValidator();

  const Uuid = rMatchdata.Uuid || "";

  const propertyAddressGroup: AggregateProperty[] = [
    {
      label: "UUID",
      accessorKey: "Rnid",
    },
    { label: "Building Type", accessorKey: "BuildingTypeObservedIn2024" },
    { label: "Business Name Observed in 2024", accessorKey: "BusinessName" },
    { label: "Phone 1 Observed in 2024", accessorKey: "Phone1" },
    { label: "Phone 2 Observed in 2024", accessorKey: "Phone2" },
  ];
  const locationGroup: AggregateProperty[] = [
    { label: "Street", accessorKey: "StreetName" },
    { label: "Settlement", accessorKey: "Settlement" },
    { label: "Plus Code 1", accessorKey: "PlusCode1" },
    { label: "Plus Code 2", accessorKey: "PlusCode2" },
  ];

  const queryVariables: Query_RootRtcs_Gm_Dev_Africa360view_Africa360ViewKanifing_AggregateArgs =
    {
      where: { Uuid: { _eq: Uuid } },
      limit: 1,
    };

  const { data, error } = useGetAfrica360ViewKanifingPropertiesFilterQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !Uuid,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const handleError = useAppErrorHandler(error);

  React.useEffect(() => {
    if (data && data.rtcs_gm_dev_Fnafrica360view_Africa360ViewKanifing.length === 0) {
      handleError(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          `Invalid Search Params`,
        ),
      );
    }
  }, [data, handleError]);

  // memoize the data
  const propertyData = React.useMemo(
    () => data?.rtcs_gm_dev_Fnafrica360view_Africa360ViewKanifing[0] || null,
    [data],
  );

  if (!propertyData) return null;

  const propertiesHeader = [
    {
      index: 0,
      label: propertyData.StreetName ?? "",
      type: "bold",
    },
    { index: 1, label: "RNID", type: "bold" },
    { index: 2, label: Uuid },
  ];

  const headerContent = [
    {
      index: 0,
      title: "Building Information",
      data: [
        ...propertyAddressGroup.map(({ accessorKey, label }, index) => {
          return {
            label,
            index,
            value:
              accessorKey === "Rnid"
                ? Uuid
                : accessorKey === "PropertyNo"
                  ? Uuid
                  : (propertyData[accessorKey as keyof typeof propertyData] as string | null) || "",
          };
        }),
      ],
    },

    {
      index: 1,
      title: "Location",
      data: [
        ...locationGroup.map(({ accessorKey, label }, index) => {
          return {
            label,
            index,
            value: (propertyData[accessorKey as keyof typeof propertyData] as string | null) || "",
          };
        }),
      ],
    },
  ];
  const tabsData = [
    { label: "Details", path: "details", value: 0 },
  ];

  interface RouteConfig {
    path: string | string[];
    element: JSX.Element;
  }

  const routes: RouteConfig[] = [
    {
      path: "details",
      element: <DetailsTab propertyData={propertyData} Rnid={Uuid} />,
    },
  ];

  if (appPermissionValidator?.(APP_PERMISSION.PROPERTIES_VIEW_NOTES)) {
    tabsData.push({ label: "Notes", path: "notes", value: 5 });
    routes.push({
      path: `notes/:noteID?`,
      element: <PropertyNotes />,
    });
  }

  routes.push({
    path: "*",
    element: <>Content Not Found</>,
  });

  return (
    <SC.Container disableGutters>
      <SC.Breadcrumb/>
      <SC.ListSubheader>
        <SC.SubHeader
          headerData={{
            header: propertiesHeader,
            content: headerContent,
            source: "Africa 360 View",
          }}
          tabsData={tabsData}
        />
      </SC.ListSubheader>
      <SC.Box>
        <Routes>
          {routes.map((route, index) =>
            Array.isArray(route.path) ? (
              route.path.map((p) => (
                <Route key={`${index}-${p}`} path={p} element={route.element} />
              ))
            ) : (
              <Route key={index} path={route.path} element={route.element} />
            ),
          )}
        </Routes>
      </SC.Box>
    </SC.Container>
  );
};

export default PropertiesTabPage;
