import { TableColumnDef } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  Africa360ViewBanjulPropertiesFragment,
  Rtcs_Gm_Dev_Ph_Africa360view_Africa360ViewBanjul,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type BanjulPropertiesSchema = Omit<
  Africa360ViewBanjulPropertiesFragment,
  "__typename"
>;

export interface BanjulPropertiesTableFilters {
  address: string;
  plusCode: string;
  phoneNumber: string;
  businessName: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof BanjulPropertiesTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Africa360view_Africa360ViewBanjul>
> = {
  address: ["Streetname", "Streetnumber", "Settlement", "Latitude", "Longitude", "Lga"],
  plusCode: ["Pluscode1", "Pluscode2"],
  phoneNumber: ["Phone1", "Phone2"],
  businessName: ["Busname"],
};

export const BANJUL_PROPERTIES_SCHEMA_MAP: Record<
  keyof BanjulPropertiesSchema,
  "string" | "number" | "Date"
> = {
  Uuid: "string",
  Officialbuildingtype: "string",
  Buildingtype: "string",
  Pluscode1: "string",
  Pluscode2: "string",
  Streetnumber: "string",
  Streetname: "string",
  Oldstreetname: "string",
  Settlement: "string",
  Lga: "string",
  Znumberofstoreys: "number",
  Buildingaream2: "number",
  Totalbuildingarea: "number",
  Wallmaterial: "string",
  Roofmaterial: "string",
  Buildingcentroidgps: "string",
  Latitude: "string",
  Longitude: "string",
  Busname: "string",
  Phone1: "string",
  Phone2: "string",
};

export const getAccessorType = (
  accessor:
    | keyof BanjulPropertiesSchema
    | AccessorFn<BanjulPropertiesSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;
  return BANJUL_PROPERTIES_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<BanjulPropertiesSchema>[] = [
  {
    header: "UUID",
    accessorKey: "Uuid",
    align: "left",
    cell: ({
      getValue,
      row: {
        original: { Uuid },
      },
    }) => {
      return (
        <Link to={`/properties/${Uuid}/details`} color="inherit">
          <> {Uuid}</>
        </Link>
      );
    },
  },
  {
    header: "Official Building Type",
    accessorKey: "Buildingtype",
    align: "left",
    format: "string",
  },
  {
    header: "Building Type Observed in 2024",
    accessorKey: "Buildingtype",
    align: "left",
    format: "string",
  },
  {
    header: "Plus Code 1",
    accessorKey: "Pluscode1",
    align: "left",
    format: "string",
  },
  {
    header: "Plus Code 2",
    accessorKey: "Pluscode2",
    align: "left",
    format: "string",
  },
  {
    header: "Street Number",
    accessorKey: "Streetnumber",
    align: "left",
    format: "string",
  },
  {
    header: "Street Name",
    accessorKey: "Streetname",
    align: "left",
    format: "string",
  },
  {
    header: "Old Street Name",
    accessorKey: "Oldstreetname",
    align: "left",
    format: "string",
  },
  {
    header: "Settlement",
    accessorKey: "Settlement",
    align: "left",
    format: "string",
  },
  {
    header: "LGA",
    accessorKey: "Lga",
    align: "left",
    format: "string",
  },
  {
    header: "Number of Storeys",
    accessorKey: "Znumberofstoreys",
    align: "right",
    format: "number",
  },
  {
    header: "Building Footprint Area M2",
    accessorKey: "Buildingaream2",
    align: "right",
    format: "number",
  },
  {
    header: "Total Building Area M2",
    accessorKey: "Totalbuildingarea",
    align: "right",
    format: "number",
  },
  {
    header: "Wall Material",
    accessorKey: "Wallmaterial",
    align: "left",
    format: "string",
  },
  {
    header: "Roof Material",
    accessorKey: "Roofmaterial",
    align: "left",
    format: "string",
  },
  {
    header: "Building Centroid GPS",
    accessorKey: "Buildingcentroidgps",
    align: "left",
    format: "string",
  },
  {
    header: "Latitude",
    accessorKey: "Latitude",
    align: "left",
    format: "string",
  },
  {
    header: "Longitude",
    accessorKey: "Longitude",
    align: "left",
    format: "string",
  },
  {
    header: "Business Name Observed in 2024",
    accessorKey: "Busname",
    align: "left",
    format: "string",
  },
  {
    header: "Phone 1 Observed in 2024",
    accessorKey: "Phone1",
    align: "left",
    format: "string",
  },
  {
    header: "Phone 2 Observed in 2024",
    accessorKey: "Phone2",
    align: "left",
    format: "string",
  },
];
