import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";

export interface AggregatePropertyDetails {
  index: number;
  label: string;
  accessorKey?: string;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

const details: AggregatePropertyDetails[] = [
  {
    index: 1,
    accessorKey: "Buildingaream2",
    label: "Building Footprint Area M2",
  },
  {
    index: 2,
    accessorKey: "Buildingaream2",
    label: "Total Building Area M2",
  },
  {
    index: 3,
    accessorKey: "Buildingtype",
    label: "Official Building Type",
  },
  {
    index: 4,
    accessorKey: "Buildingtype",
    label: "Building Type Observed in 2024",
  },
  {
    index: 5,
    accessorKey: "Pluscode1",
    label: "Plus Code 1",
  },
  {
    index: 6,
    accessorKey: "Pluscode2",
    label: "Plus Code 2",
  },
  {
    index: 7,
    accessorKey: "Streetnumber",
    label: "Street Number",
  },
  {
    index: 8,
    accessorKey: "Streetname",
    label: "Street Name",
  },
  {
    index: 9,
    accessorKey: "Oldstreetname",
    label: "Old Street Name",
  },
  {
    index: 10,
    accessorKey: "Settlement",
    label: "Settlement",
  },
  {
    index: 11,
    accessorKey: "Znumberofstoreys",
    label: "Number of Storeys",
  },
  {
    index: 12,
    accessorKey: "Roofmaterial",
    label: "Roof Material",
  },
  {
    index: 13,
    accessorKey: "Wallmaterial",
    label: "Wall Material",
  },
];

const particularsResidentialSFH: AggregatePropertyDetails[] = [
  {
    index: 0,
    accessorKey: "AccomodationOthers",
    label: "Other Data ",
  },
  {
    index: 1,
    accessorKey: "AccomodationBuiltUpArea",
    label: "Building Square Meters",
  },
];

const accomodationsResidentialSFH: AggregatePropertyDetails[] = [
  {
    index: 0,
    accessorKey: "AccomodationBedrooms",
    label: "No. of Bedrooms by Source",
  },
  {
    index: 1,
    accessorKey: "DerivedNoOfBedrooms",
    label: "No. of Bedrooms Derived",
  },
];

const particularCommercialRetailAndOffices: AggregatePropertyDetails[] = [
  {
    index: 0,
    accessorKey: "SquareMeters",
    label: "Total building square area (m2)",
  },
  {
    index: 1,
    accessorKey: "RentPerSquareMeter",
    label: "Approximate Rent Per Square Meter",
  },
  {
    index: 2,
    accessorKey: "MonthlyGrossIncome",
    label: "Approximate Monthly Gross Income",
    format: "currency",
  },
  {
    index: 3,
    accessorKey: "AnnualGrossValue",
    label: "Approximate Annual Gross Income",
    format: "currency",
  },
  {
    index: 4,
    accessorKey: "CurrencyType",
    label: "Currency Type",
  },
];

const particularWarehouseAndBars: AggregatePropertyDetails[] = [
  {
    index: 0,
    accessorKey: "SquareMeters",
    label: "Total building square area (m2)",
  },
  {
    index: 1,
    accessorKey: "RentPerSquareMeter",
    label: "Approximate Rent Per Square Meter",
  },
  {
    index: 2,
    accessorKey: "Grossvalue",
    label: "Approximate Monthly Gross Income",
    format: "currency",
  },
  {
    index: 3,
    accessorKey: "AnnualGrossValue",
    label: "Approximate Annual Gross Income",
    format: "currency",
  },
  {
    index: 4,
    accessorKey: "CurrencyType",
    label: "Currency Type",
  },
];

const particulars: AggregatePropertyDetails[] = [
  {
    index: 0,
    accessorKey: "ParticularsBlockName",
    label: "Block Name",
  },
  {
    index: 1,
    accessorKey: "ParticularsBlockNumber",
    label: "Block No.",
  },
  {
    index: 2,
    accessorKey: "ParticularsFolioNumber",
    label: "Folio No.",
  },
  {
    index: 3,
    accessorKey: "ParticularsHouseNumber",
    label: "House No.",
  },
  {
    index: 4,
    accessorKey: "ParticularsIsTitled",
    label: "Titled?",
  },
  {
    index: 5,
    accessorKey: "ParticularsPlotNumber",
    label: "Plot No.",
  },
  {
    index: 6,
    accessorKey: "ParticularsPlotShape",
    label: "Plot Shape",
  },
  {
    index: 7,
    accessorKey: "ParticularsPropertyName",
    label: "Name",
  },
  {
    index: 8,
    accessorKey: "ParticularsRoadStreet",
    label: "Road",
  },
  {
    index: 9,
    accessorKey: "ParticularsTypeOfLandInterest",
    label: "Type of Land Interest",
  },
];

const accomodations: AggregatePropertyDetails[] = [
  {
    index: 0,
    accessorKey: "AccomodationBedrooms",
    label: "Bed Rooms",
  },
  {
    index: 1,
    accessorKey: "AccomodationBuiltUpArea",
    label: "Build Up Area",
    format: "area",
  },
  {
    index: 2,
    accessorKey: "AccomodationWc",
    label: "WC",
  },
  {
    index: 3,
    accessorKey: "AccomodationWcBathroom",
    label: "Bathroom",
  },
  {
    index: 4,
    accessorKey: "AccomodationWcShower",
    label: "Shower",
  },
];

const otherData: AggregatePropertyDetails[] = [
  {
    index: 0,
    accessorKey: "ParticularsRoadStreet",
    label: "Street",
  },
  {
    index: 1,
    accessorKey: "Village_x",
    label: "Village",
  },
  {
    index: 2,
    accessorKey: "ParticularsVolume",
    label: "Volume",
  },
  {
    index: 3,
    accessorKey: "ParticularsFolioNumber",
    label: "Folio",
  },
];
export default {
  details,
  particulars,
  accomodations,
  otherData,
  particularsResidentialSFH,
  accomodationsResidentialSFH,
  particularCommercialRetailAndOffices,
  particularWarehouseAndBars,
};
